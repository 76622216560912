var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Transaction","show":_vm.show,"size":"xl","fetching":_vm.fetching},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start w-100"},[(_vm.transaction.id && !_vm.deleting)?_c('TButtonDelete',{attrs:{"tooltip":"Delete transaction","options":{ content: 'Delete transaction' },"variant":""},on:{"click":_vm.onDeleteTransaction}}):_c('TSpinner',{attrs:{"loading":_vm.deleting}})],1)]},proxy:true}])},[(_vm.transaction.id)?_c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"data":_vm.transaction,"fields":_vm.t1Fields,"splitLeft":5},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var value = ref.value;
return [_c('SMessageCard',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateTransaction('card_id', $event)}}})]}},{key:"code_ref",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"messageOptions":{ noTranslate: true },"editable":""},on:{"change":function($event){return _vm.updateTransaction('code_ref', $event)}}})]}},{key:"voucher_date",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"dateOnly":"","editable":""},on:{"change":function($event){return _vm.updateTransaction('voucher_date', $event)}}})]}},{key:"object",fn:function(ref){
var value = ref.value;
return [(value)?_c('SMessageWallet',{attrs:{"data":_vm.transaction}}):_vm._e()]}},{key:"credit_order_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('TLink',{attrs:{"content":value,"to":_vm.lodash.getReferenceLink('credit_order', value)}}):_vm._e()]}},{key:"currency_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageCurrency',{attrs:{"id":value}})]}},{key:"amount",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.transaction.currency_id,"editable":""},on:{"change":function($event){return _vm.updateTransaction('amount', $event)}}})]}},{key:"transactions_remaining",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.transaction.currency_id}})]}},{key:"description",fn:function(ref){
var value = ref.value;
return [_c('TMessageText',{attrs:{"value":value,"messageOptions":{ noTranslate: true, truncate: 3 },"editable":""},on:{"change":function($event){return _vm.updateTransaction('description', $event)}}})]}}],null,false,1549383911)})],1),_c('CCol',{attrs:{"md":"6"}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"data":_vm.transaction,"fields":_vm.t2Fields,"splitLeft":5},scopedSlots:_vm._u([{key:"type_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageTransactionType',{staticClass:"text-truncate-1",attrs:{"value":value}})]}},{key:"prepared_by",fn:function(ref){
var value = ref.value;
return [_c('SMessageUser',{attrs:{"user":value}})]}},{key:"debit_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageAccount',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateTransaction('debit_id', $event)}}})]}},{key:"credit_id",fn:function(ref){
var value = ref.value;
return [_c('SMessageAccount',{attrs:{"value":value,"editable":""},on:{"change":function($event){return _vm.updateTransaction('credit_id', $event)}}})]}},{key:"created_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}},{key:"updated_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}}],null,false,3191745346)})],1)],1),_c('hr'),_c('CRow',[_c('CCol',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"Arising from","bold":""}}),(_vm.transaction.parent)?_c('SCardTransactionItem',{attrs:{"transaction":_vm.transaction.parent},on:{"click":function($event){return _vm.getRelatedTransaction(_vm.transaction.parent)}}}):_vm._e(),(!_vm.transaction.parent)?_c('TMessageNotFound'):_vm._e()],1),_c('CCol',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"Reference","bold":""}}),_c('TTableSimple',{attrs:{"fields":_vm.referenceFields,"items":_vm.transaction.references || [],"noResult":""},on:{"update:create":_vm.onInputReferenceItem},scopedSlots:_vm._u([{key:"_-header",fn:function(){return [(
                !_vm.transaction ||
                (!!_vm.transaction &&
                  _vm.transaction.references &&
                  !_vm.transaction.references.length)
              )?_c('div',[_c('TButton',{attrs:{"icon":"cil-plus","size":"sm","tooltip":"Add transaction reference","variant":"ghost"},on:{"click":function($event){_vm.showModalAddNewReference = true}}})],1):_vm._e()]},proxy:true},{key:"_",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TButtonRemove',{on:{"click":function($event){return _vm.onRemoveReference(item)}}})],1)]}},{key:"receiptable_type",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":_vm.getContentReferenceType(item.receiptable_type)}})],1)]}},{key:"receiptable_id",fn:function(ref){
              var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink(
                    ("" + (item.receiptable_type == 'container'
                        ? 'invoice'
                        : item.receiptable_type)),
                    item.receiptable_id
                  ),"content":item.receiptable_id}})],1)]}}],null,false,2202112274)})],1),_c('CCol',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"Arising","bold":""}}),_vm._l((_vm.transaction.children),function(transaction){return _c('div',{key:transaction.id},[_c('SCardTransactionItem',{attrs:{"transaction":transaction},on:{"remove":function($event){return _vm.onRemoveChildTransaction(transaction)},"click":function($event){return _vm.getRelatedTransaction(transaction)}}})],1)}),(!(_vm.transaction.children ? _vm.transaction.children.length : false))?_c('TMessageNotFound'):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.allow_add_child_transaction)?_c('TButton',{attrs:{"content":"Distribute transaction","color":"primary"},on:{"click":function($event){return _vm.$refs.modalAddNewChildTransactionRef.open(_vm.transaction)}}}):_vm._e()],1)],2)],1),_c('hr'),_c('div',{staticClass:"w-100"},[_c('TInputFile',{attrs:{"value":_vm.fileRecords,"options":{ linkable: true }},on:{"update:value":function($event){_vm.fileRecords=$event},"deleted":_vm.deleteFile,"selected":_vm.uploadReceipt}})],1)],1):_vm._e(),_c('ModalAddNewTransactionReference',{attrs:{"show":_vm.showModalAddNewReference,"transaction_id":_vm.transaction.id},on:{"update:show":function($event){_vm.showModalAddNewReference=$event},"create_success":_vm.onRefresh}}),_c('ModalAddNewChildTransaction',{ref:"modalAddNewChildTransactionRef",on:{"create_success":_vm.onRefresh}}),_c('ModalRelatedTransaction',{attrs:{"show":_vm.showModalRelatedTransaction,"transaction":_vm.relatedTransaction.data,"loading":_vm.relatedTransaction.loading},on:{"update:show":function($event){_vm.showModalRelatedTransaction=$event},"update:transaction":function($event){return _vm.$set(_vm.relatedTransaction, "data", $event)},"change-transaction":function ($event) { return _vm.getRelatedTransaction($event); },"update":_vm.updateRelatedTransaction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }